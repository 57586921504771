import { GET_ALL_PRO_CHAT, GET_ALL_MSG_CHAT, GET_PRO_INFO_CHAT, SEND_MESSAGE, UPDATE_MSG, ADD_IMG, CLEAN_STATE } from '../actions/typesAction';


const initialState = {
    
    usersChat: null,
    proInfo: null,
    messagePro: [],
    img: {name:""},
    
}

const chatReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_PRO_CHAT:
            return {
                ...state,
                usersChat : payload
            }
        case GET_PRO_INFO_CHAT:
            return {
                ...state,
                proInfo : payload
            }
        case GET_ALL_MSG_CHAT:
            let varMsg = [...state.messagePro, ...payload.msg]
            return {
                ...state,
                    proInfo: payload.proInfo,
                    messagePro: varMsg
            }
            case SEND_MESSAGE:
                return {
                    ...state,
                        messageEnv: payload
                       
            }
            case UPDATE_MSG:
                return {
                    ...state,
                    messagePro: payload
                    
            }
            case ADD_IMG:
                return {
                    ...state,
                    img: payload,
                    
            }
            case CLEAN_STATE:
                return {
                    ...state,
                        
                        messagePro: []
                }
                    
                    
            
        
        default:
            return state;
    }
}

export default chatReducer;