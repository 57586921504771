import { UPDATE_USER_STRIPE, UPDATE_SCHEDULE_PRO, UPDATE_USER, LOGIN_PRO_SUCCESS, LOGIN_CLIENT_SUCCESS, UPDATE_DESCRIPTION, UPDATE_AVAILABILITY_PRO, UPDATE_GENERIC_ERROR } from '../actions/typesAction';

const initState = {
    isLoggedIn: false,
    user: '',
    typeOfUser: '',
    access_token: '',
    authError: null,
    loading: false
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case "CLEAN_ERROR": 
            return {
                ...state,
                authError: null,
            };
        case LOGIN_PRO_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload.pro,
                typeOfUser: 'todoo',
                access_token: action.payload.access_token
            };
        case LOGIN_CLIENT_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload.client,
                typeOfUser: 'client',
                access_token: action.payload.access_token
            };
        case 'LOG_OUT':
            localStorage.clear();
            return initState;
        case UPDATE_SCHEDULE_PRO:
             return {
                 ...state,
                 user: {
                     ...state.user, 
                     availability: action.payload
                }
             };
        case UPDATE_AVAILABILITY_PRO:
            return {
                ...state,
                user: {
                    ...state.user, 
                    unavailability: [
                        ...state.user.unavailability,
                        action.payload
                    ]
                }
            };
        case UPDATE_DESCRIPTION:
            return {
                ...state,
                user: {
                    ...state.user, 
                    self_description: action.payload
                }
            };
        case UPDATE_USER: 
             return {
                 ...state,
                 user: action.payload,
                 authError: null
             };
        case UPDATE_USER_STRIPE: 
            return {
                ...state,
                authError: action.payload.error
            };
        case UPDATE_GENERIC_ERROR: 
            return {
                ...state,
                authError: action.payload.error
            };
        default:
            return state;
    }
}

export default authReducer;