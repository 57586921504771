import { LOGIN_PRO_SUCCESS, UPDATE_USER_STRIPE, UPDATE_USER, GET_TIME_SCHEDULED_ACTIVITIES, GET_SCHEDULED_ACTIVITIES } from './typesAction';
import axios from 'axios';
import { setAlert } from './alertAction';
import config from '../../../config.json';
import { activateUserAction, cleanError, updateDescriptionAuth, updateScheduleAuth, updateUnavailabilityPro } from './authAction';
import moment from 'moment';


export const updatePro = (user) => dispatch => {
    dispatch({
        type: UPDATE_USER,
        payload: user
    })
}

export const updateAvailabilities = (token, proId, enableTime, alertMessage) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const content = {
            availability: {
                Mo: enableTime.Mo,
                Tu: enableTime.Tu,
                We: enableTime.We,
                Th: enableTime.Th,
                Fr: enableTime.Fr,
                Sa: enableTime.Sa,
                Su: enableTime.Su
            }
        }
        const data = JSON.stringify(content);
        
        axios.post(`${config.API_SERVER}/api/pro/availability/update`, data, headers)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateScheduleAuth(res.data))
            }
        })
        .then(() => {
            dispatch(setAlert(alertMessage, 'success'));
        })
        .catch((error) => {
            console.log(error)
        });
        
    } catch (error) {
        console.log(error)
    }
}


export const updateMyCriteria = (token, user, formData, alertMessage) => async dispatch => {
    try{
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const newAddres = formData?.address ?? user.address

        const data = {
            'criterion': {
                'scope': formData.scope,
                'oven': formData.oven,
                'fridge': formData.fridge,
                'bed': formData.bed,
                'vacuum': formData.vacuum,
                'product_ecological': formData.product_ecological,
                'product_standard': formData.product_standard,
                'with_client': formData.with_client === '1' ? true : false,
                'cat': formData.cat === '1' ? true : false,
                'dog': formData.dog === '1' ? true : false
            },
            'address': newAddres
        }
        
        await axios.post(`${config.API_SERVER}/api/pro/updateCriterion`, data, headers)
        .then((res) => {

            if(res.status === 200){

                user.address = {
                    ...user.address,
                    rue: res.data.address.rue,
                    postcode: res.data.address.postcode,
                    city: res.data.address.city,
                    province: res.data.address.province,
                    lat: res.data.address.lat,
                    lng: res.data.address.lng
                }
                user.criterion.postcode = res.data.address.postcode;
                user.criterion.scope = res.data.criterion.scope;
                user.criterion.oven = res.data.criterion.oven ? 1 : 0;
                user.criterion.fridge = res.data.criterion.fridge ? 1 : 0;
                user.criterion.bed = res.data.criterion.bed ? 1 : 0;
                user.criterion.vacuum = res.data.criterion.vacuum ? 1 : 0;
                user.criterion.product_ecological = res.data.criterion.product_ecological ? 1 : 0;
                user.criterion.product_standard = res.data.criterion.product_standard ? 1 : 0;
                user.criterion.with_client = res.data.criterion.with_client ? 1 : 0;
                user.criterion.cat = res.data.criterion.cat ? 1 : 0;
                user.criterion.dog = res.data.criterion.dog ? 1 : 0;

                dispatch({
                    type: UPDATE_USER,
                    payload: user
                })

                let datalog = JSON.parse(localStorage.getItem("userLoggedIn"));
                datalog.payload.pro.address = res.data.address
                datalog.payload.pro.criterion = {
                    ...datalog.payload.pro.criterion,
                    scope: res.data.criterion.scope,
                    oven: res.data.criterion.oven ? 1 : 0,
                    fridge: res.data.criterion.fridge ? 1 : 0,
                    bed: res.data.criterion.bed ? 1 : 0,
                    vacuum: res.data.criterion.vacuum ? 1 : 0,
                    product_ecological: res.data.criterion.product_ecological ? 1 : 0,
                    product_standard: res.data.criterion.product_standard ? 1 : 0,
                    with_client: res.data.criterion.with_client ? 1 : 0,
                    cat: res.data.criterion.cat ? 1 : 0,
                    dog: res.data.criterion.dog ? 1 : 0    
                }

                localStorage.setItem(
                    "userLoggedIn",
                    JSON.stringify({
                        type: LOGIN_PRO_SUCCESS,
                        payload: datalog.payload,
                    })
                );

                dispatch(setAlert(alertMessage, 'success'))
            }
        })
        .catch((error) => {
            console.log('Error: ', error)
        })

    }catch(error){
        console.log(error)
    }
}

export const updateTaxes = (token, status, user, alertMessage) => async dispatch => {
    try{
        
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = { 
            status: status === '1' ? true : false
        }

        await axios.post(`${config.API_SERVER}/api/pro/tax`, data, headers)
        .then((res) => {
            if (res.status === 200){
                user.tax = parseInt(status, 10)
                dispatch({
                    type: UPDATE_USER,
                    payload: user
                })
                dispatch(setAlert(alertMessage, 'success'))
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const createStripe = (token, user, formData, alertMessage) => async dispatch => {
    try{
        
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = { 
            currency: formData.currency,
            holderName: formData.holderName,
            holderType: formData.holderType,
            transit: formData.transit,
            account: formData.account
        }
    
        await axios.post(`${config.API_SERVER}/api/pro/account/create`, data, headers)
        .then((res) => {            
            if (res.status === 200){
                user.credential.stripe_id = res.data.id
                dispatch({
                    type: UPDATE_USER,
                    payload: user
                })
                dispatch(setAlert(alertMessage, 'success'))
            }
        })
        .catch((error) => {
            dispatch({
                type: UPDATE_USER_STRIPE,
                payload: { 
                    error: error.response.data.error, 
                    status: error.status
                }
            })
            dispatch(setAlert(error.response.data.error, 'error'))
            console.log(error.response.status, error.response.data.error)

        })

    }catch(error){
        console.log(error)
    }
}

export const createStripeFirstLogin = (token, user, formData, alertMessage, criterion, enableTime, history) => async dispatch => {
    try{
        
        dispatch(cleanError())
        
        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = { 
            currency: formData.currency,
            holderName: formData.holderName,
            holderType: formData.holderType,
            transit: formData.transit,
            account: formData.account
        }

        await axios.post(`${config.API_SERVER}/api/pro/account/create`, data, headers)
        .then((res) => {            
            if (res.status === 200){                
                user.credential.stripe_id = res.data.id
                user.availability.Mo = enableTime.Mo
                user.availability.Tu = enableTime.Tu
                user.availability.We = enableTime.We
                user.availability.Th = enableTime.Th
                user.availability.Fr = enableTime.Fr
                user.availability.Sa = enableTime.Sa
                user.availability.Su = enableTime.Su

                dispatch({type: UPDATE_USER,payload: user})

                let datalog = JSON.parse(localStorage.getItem("userLoggedIn"));
                datalog.payload.pro.availability.Mo = enableTime.Mo
                datalog.payload.pro.availability.Tu = enableTime.Tu
                datalog.payload.pro.availability.We = enableTime.We
                datalog.payload.pro.availability.Th = enableTime.Th
                datalog.payload.pro.availability.Fr = enableTime.Fr
                datalog.payload.pro.availability.Sa = enableTime.Sa
                datalog.payload.pro.availability.Su = enableTime.Su
        
                localStorage.setItem(
                    "userLoggedIn",
                    JSON.stringify({
                        type: LOGIN_PRO_SUCCESS,
                        payload: datalog.payload,
                    })
                );        
            }
        })
        .then(() => {
            user.self_description = formData.descriptionPro
            dispatch(updateDescriptionAuth(token, formData.descriptionPro))
            dispatch(updateTaxes(token, formData.tpsTvq, user, alertMessage))
            dispatch(updateMyCriteria(token, user, criterion, alertMessage))
            dispatch(updateAvailabilities(token, user.id, enableTime, alertMessage))
            dispatch(activateUserAction(token, user))
            dispatch(setAlert(alertMessage, 'success'))
        })
        .then(() => {
            history('/todoo/welcome')
        })
        .catch((error) => {
            dispatch({
                type: UPDATE_USER_STRIPE,
                payload: { 
                    error: error.response.data.error, 
                    status: error.status
                }
            })
            dispatch(setAlert(error.response.data.error, 'error'))
            console.log(error.response.status, error.response.data.error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getTimeSheetPro = (token, date) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            date: date
        }
        await axios.post(`${config.API_SERVER}/api/pro/reservation/timeSheet`, data, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch({
                    type: GET_TIME_SCHEDULED_ACTIVITIES,
                    payload: res.data.timeSheet
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getScheduledActivitiesPro = (token, date) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            date: date
        }

        await axios.post(`${config.API_SERVER}/api/pro/calender/schedules`, data, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch({
                    type: GET_SCHEDULED_ACTIVITIES,
                    payload: res.data.reservations
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const updateDescriptionPro = (token, description) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            description: description
        }
        
        await axios.post(`${config.API_SERVER}/api/pro/description`, data, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(updateDescriptionAuth(description))
            }
        })
        .catch((error) => {
            console.log(error.response.data);
        })

    }catch(error){
        console.log(error.response.data);
    }
}

export const timePeriodLocked = (token, pro, date, period, unavailability, availability) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            proId: pro.id,
            date: date,
            period: period
        }

        await axios.post(`${config.API_SERVER}/api/pro/unavailability/create`, data, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(updateUnavailabilityPro(res.data))

                const dayName = moment(date)
                if(availability[dayName.format('dd')] === 1 || availability[dayName.format('dd')] === 2 ){
                    dispatch(timePeriodLockedAllDay(token, pro, date))
                }
        
                let exist = null
                if(period === 1){
                    exist = unavailability.filter(row => (row.date_selected === date && row.period === 2))
                    if(exist.length > 0) {
                        dispatch(timePeriodLockedAllDay(token, pro, date))
                    }
                }
                if(period === 2){
                    exist = unavailability.filter(row => (row.date_selected === date && row.period === 1))
                    if(exist.length > 0) {
                        dispatch(timePeriodLockedAllDay(token, pro, date))
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const timePeriodLockedAllDay = (token, pro, date) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            proId: pro.id,
            date: date,
            period: 3
        }

        await axios.post(`${config.API_SERVER}/api/pro/unavailability/create`, data, headers)
        .then((res) => {
            if(res.status === 200){
                
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}


export const timePeriodUnLocked = (token, pro, date, period) => async dispatch => {
    try{

        const headers = {
            headers: {'Authorization': `Bearer ${token}`}
        }

        const data = {
            proId: pro.id,
            date: date,
            period: period
        }

        await axios.post(`${config.API_SERVER}/api/pro/availability/create`, data, headers)
        .then((res) => {
            if(res.status === 200){
                dispatch(updateUnavailabilityPro(res.data))
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}
