import {
    GET_RESERVATIONS_LIST,
    GET_A_REQUEST,
    PROCESSING,
    GET_TIME_SCHEDULED_ACTIVITIES,
    GET_SCHEDULED_ACTIVITIES,
    GET_ACTIVITIES,
    PROFESSIONAL_CONFIRMED_BY_THE_CLIENT,
    GET_ACTIVITIES_PROGRAMMED,
    UPDATE_STATE_RESERVATION,
    PAYMENT_CHANGED_STATUS,
    RESERVATION_STATUS,
    GET_HISTORY_LIST,
    UPDATERESERVATIONID,
    GET_RESERVATIONS_LIST_PRO
} from '../actions/typesAction'

const initState = {
    reservationsListPro: {
        newReq: {
            statusId: '1',
            records: null
        },
        pendingReq: {
            statusId: '2',
            records: null
        },
        acceptedReq: {
            statusId: '4',
            records: null
        }
    },
    reservations: null,
    reservation: null,
    reservationHistoryList: null,
    tymeScheduleActivities: null,
    scheduledActivitiesPro: null,
    activities: null,
    proActivitiesProgrammed: null,
    reservationId: null,
    statusId: null,
    status: null,
    refuseStatus: null,
    isFinished: 0,
    proChosen: null
};

const reservationReducer = (state = initState, action) => {
    const { type, payload } = action

    switch (type) {
        case PROCESSING:
            return initState;
        case UPDATERESERVATIONID:
            return {
                ...state,
                reservationId: payload.reservationId,
                statusId: payload.statusId,
                reservation: payload.reservationId === null ? null : state.reservation
            }
        case GET_RESERVATIONS_LIST_PRO:
            return {
                ...state,
                reservationsListPro: payload,
                reservation: null
            };
        case GET_RESERVATIONS_LIST:
            return {
                ...state,
                reservations: payload,
                reservation: null
            };
        case GET_HISTORY_LIST:
            return {
                ...state,
                reservationHistoryList: payload,
                reservations: null,
                reservation: null,
                status: null,
                refuseStatus: null,
            };
        case GET_A_REQUEST:
            return {
                ...state,
                reservation: payload.pro_reservation,
                status: payload.status
            };
        case GET_TIME_SCHEDULED_ACTIVITIES:
            return {
                ...state,
                tymeScheduleActivities: payload
            }
        case GET_SCHEDULED_ACTIVITIES:
            return {
                ...state,
                scheduledActivitiesPro: payload
            }
        case GET_ACTIVITIES_PROGRAMMED:
            return {
                ...state,
                proActivitiesProgrammed: payload
            }
        case GET_ACTIVITIES:
            return {
                ...state,
                activities: payload
            }
        case PROFESSIONAL_CONFIRMED_BY_THE_CLIENT:
            return {
                ...state,
                status: payload.status,
                proChosen: payload.proChosen
            }

        case PAYMENT_CHANGED_STATUS:
            return {
                ...state,
                status: payload
            }
        case UPDATE_STATE_RESERVATION:
            return {
                ...state,
                isFinished: payload
            }
        case RESERVATION_STATUS:
            return {
                ...state,
                refuseStatus: payload,
                reservations: null,
                reservation: null
            }
        default:
            return initState
    }
}

export default reservationReducer 