import React from 'react'
import { useDispatch, useStore } from 'react-redux'
import { Navigate } from 'react-router-dom'
import jwt_decode from "jwt-decode"

import { setAlert } from '../../common/store/actions/alertAction'
import { logOutActionNoToken } from '../../common/store/actions/authAction'
import { useTranslation } from 'react-i18next'

export const PrivateRoute = ({Component, path}) => {
    const store = useStore()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    
    const auth = store.getState().auth
    const { isLoggedIn, access_token, typeOfUser } = auth //loading
    let expiredToken = false
    
    let varLink = '/'
    if (path.includes(typeOfUser)){  
        if(isLoggedIn)
        {
            const decoded = jwt_decode(access_token);
            const exp = (decoded.exp*1000) //- 86400000
            const rightNow = new Date().getTime()

            if(exp <= rightNow){
                localStorage.setItem('urlRedirect', window.location.pathname);
                if(path.split('/')[1] === 'todoo' || path.split('/')[1] === 'client'){
                    //varLink = `/${path.split('/')[1]}/login`
                    varLink = `/login`
                }
                //dispatch(logOutAction())
                dispatch(logOutActionNoToken())
                dispatch(setAlert(t("SESSIONFINISHED"), 'success'))
                expiredToken = true
            }

        }else{
            localStorage.setItem('urlRedirect', window.location.pathname);
            if(path.split('/')[1] === 'todoo' || path.split('/')[1] === 'client'){
                //varLink = `/${path.split('/')[1]}/login`
                varLink = `/login`
            }
        }
    }else {
        
        dispatch(logOutActionNoToken())
        dispatch(setAlert(t("SESSIONFINISHED"), 'success'))
        expiredToken = true
        //return (<Navigate to={`/${typeOfUser}/login`} />)
        return (<Navigate to={`/login`} />)
    }

    if(!isLoggedIn){
        //return (<Navigate to={`/${path.split('/')[1]}/login`} />)
        return (<Navigate to={`/login`} />)
    }

    if(expiredToken){
        return (<Navigate to={varLink} />)
    }

    return  Component
}
