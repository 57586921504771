export const PROCESSING = 'PROCESSING';
/* GENERATE ALERT. IT IS GOING TO DISPLAY MESSAGES IN OUR APP */
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const RESET_ALERT = 'RESET_ALERT';

/* ACTIVATE A USER AFTER A FIRST LOGGED IN */
export const ACTIVATE_USER = 'ACTIVATE_USER';

/* PROFESSONAL */
export const LOGIN_PRO_SUCCESS = 'LOGIN_PRO_SUCCESS';
export const UPDATE_SCHEDULE_PRO = 'UPDATE_SCHEDULE_PRO';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_TIME_SCHEDULED_ACTIVITIES = 'GET_TIME_SCHEDULED_ACTIVITIES';
export const GET_SCHEDULED_ACTIVITIES = 'GET_SCHEDULED_ACTIVITIES';
export const GET_ACTIVITIES_PROGRAMMED = 'GET_ACTIVITIES_PROGRAMMED';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_AVAILABILITY_PRO = 'UPDATE_AVAILABILITY_PRO';
export const UPDATE_USER_STRIPE = 'UPDATE_USER_STRIPE';
export const UPDATE_GENERIC_ERROR = 'UPDATE_GENERIC_ERROR';
/* RESERVATION */
export const GET_RESERVATIONS_LIST_PRO = 'GET_RESERVATIONS_LIST_PRO';
export const GET_RESERVATIONS_LIST = 'GET_RESERVATIONS_LIST';
export const GET_A_REQUEST = 'GET_A_REQUEST';
export const UPDATE_STATE_RESERVATION = 'UPDATE_STATE_RESERVATION';
export const RESERVATION_STATUS = 'RESERVATION_STATUS';
export const RESERVATION_CLEAN_STATUS = 'RESERVATION_CLEAN_STATUS';
export const TOTAL_RESERVATION = 'TOTAL_RESERVATION';
export const UPDATERESERVATIONID = 'UPDATERESERVATIONID'

/* CLIENT */
export const LOGIN_CLIENT_SUCCESS = 'LOGIN_CLIENT_SUCCESS';

export const ESTIMATION_LOCATION_UPDATE = 'ESTIMATION_LOCATION_UPDATE';
export const ESTIMATION_HOUSING_UPDATE = 'ESTIMATION_HOUSING_UPDATE';
export const ESTIMATION_HOUSING_VALIDATION_SUCCESS = 'ESTIMATION_HOUSING_SUCCESS';
export const ESTIMATION_HOUSING_VALIDATION_ERROR = 'ESTIMATION_HOUSING_ERROR';
export const ESTIMATION_BENEFIT_UPDATE = 'ESTIMATION_BENEFIT_UPDATE';
export const ESTIMATION_BENEFIT_VALIDATION_SUCCESS = 'ESTIMATION_BENEFIT_SUCCESS';
export const ESTIMATION_BENEFIT_VALIDATION_ERROR = 'ESTIMATION_BENEFIT_ERROR';
export const ESTIMATION_PERSONAL_DATA_UPDATE = 'ESTIMATION_PERSONAL_DATA_UPDATE';
export const ESTIMATION_NEW_REQUEST = 'ESTIMATION_NEW_REQUEST';
export const FETCH_ESTIMATION = 'FETCH_ESTIMATION';
export const FETCH_NEW_ESTIMATION = 'FETCH_NEW_ESTIMATION';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const PROFESSIONAL_CONFIRMED_BY_THE_CLIENT = 'PROFESSIONAL_CONFIRMED_BY_THE_CLIENT';
export const PAYMENT_CHANGED_STATUS = 'PAYMENT_CHANGED_STATUS';
export const GET_LOCKED_DATE = 'GET_LOCKED_DATE';
export const GET_LOCKED_DATE_RESET = 'GET_LOCKED_DATE_RESET';

/* CONCIERGE */
export const LOGIN_CLIENT_SUCCESS_C = 'LOGIN_CLIENT_SUCCESS_C';
export const ESTIMATION_BANDERIE_UPDATE = 'ESTIMATION_BANDERIE_UPDATE'
export const ESTIMATION_LOCATION_UPDATE_C = 'ESTIMATION_LOCATION_UPDATE_C';
export const ESTIMATION_HOUSING_UPDATE_C = 'ESTIMATION_HOUSING_UPDATE_C';
export const ESTIMATION_HOUSING_VALIDATION_SUCCESS_C = 'ESTIMATION_HOUSING_SUCCESS_C';
export const ESTIMATION_HOUSING_VALIDATION_ERROR_C = 'ESTIMATION_HOUSING_ERROR_C';
export const ESTIMATION_BENEFIT_UPDATE_C = 'ESTIMATION_BENEFIT_UPDATE_C';
export const ESTIMATION_BENEFIT_VALIDATION_SUCCESS_C = 'ESTIMATION_BENEFIT_SUCCESS_C';
export const ESTIMATION_BENEFIT_VALIDATION_ERROR_C = 'ESTIMATION_BENEFIT_ERROR_C';
export const ESTIMATION_PERSONAL_DATA_UPDATE_C = 'ESTIMATION_PERSONAL_DATA_UPDATE_C';
export const ESTIMATION_NEW_REQUEST_C = 'ESTIMATION_NEW_REQUEST_C';
export const FETCH_ESTIMATION_C = 'FETCH_ESTIMATION_C';
export const FETCH_NEW_ESTIMATION_C = 'FETCH_NEW_ESTIMATION_C';
export const GET_ACTIVITIES_C = 'GET_ACTIVITIES_C';
export const PROFESSIONAL_CONFIRMED_BY_THE_CLIENT_C = 'PROFESSIONAL_CONFIRMED_BY_THE_CLIENT_C';
export const PAYMENT_CHANGED_STATUS_C = 'PAYMENT_CHANGED_STATUS_C';
export const GET_LOCKED_DATE_C = 'GET_LOCKED_DATE_C';

/* HISTORY */
export const GET_HISTORY_LIST = 'GET_HISTORY_LIST';
export const GET_A_HISTORY_RESERVATION = 'GET_A_HISTORY_RESERVATION';
/**Type Action to work with chat */
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const GET_ALL_PRO_CHAT = 'GET_ALL_PRO_CHAT';
export const GET_PRO_INFO_CHAT = 'GET_PRO_INFO_CHAT';
export const GET_ALL_MSG_CHAT = 'GET_ALL_MSG_CHAT';
export const UPDATE_MSG = 'UPDATE_MSG';
export const ADD_IMG = 'ADD_IMG';
export const CLEAN_STATE = 'CLEAN_STATE';