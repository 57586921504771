import { combineReducers } from 'redux';
import alertReducer from './alertReducer';
import registrationReducer from './registrationReducer';
import authReducer from './authReducer';
import reservationReducer from './reservationReducer';
import estimationReducer from './estimationReducer';
import chatReducer from './chatReducer'
const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    registration: registrationReducer,
    reservation: reservationReducer,
    estimation: estimationReducer,
    chat : chatReducer
})

export default rootReducer;