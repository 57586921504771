import {
    UPDATE_SCHEDULE_PRO,
    LOGIN_PRO_SUCCESS,
    LOGIN_CLIENT_SUCCESS,
    UPDATE_AVAILABILITY_PRO,
    UPDATE_GENERIC_ERROR,
    UPDATE_DESCRIPTION,
} from "./typesAction";
import axios from "axios";
import { setAlert } from "./alertAction";
import config from "../../../config.json";
import { updatePro } from "./proAction";

export const cleanError = () => async (dispatch) => {
    dispatch({
        type: "CLEAN_ERROR",
    });
};

export const updateProDescription = (descriptionPro) => async (dispatch) => {
    dispatch({
        type: UPDATE_DESCRIPTION,
        payload: descriptionPro
    })
}

export const authProAction = (formData, errorMessage, history) => async (dispatch) => {
    try {
        const data = {
            email: formData.email,
            password: formData.password,
        };

        if (formData.remember) {
            data.remember = true;
        }

        await axios
            .post(`${config.API_SERVER}/api/login/pro`, data)
            .then((res) => {
                if (res.status === 200) {

                    dispatch({
                        type: LOGIN_PRO_SUCCESS,
                        payload: res.data,
                    });

                    localStorage.setItem(
                        "userLoggedIn",
                        JSON.stringify({
                            type: LOGIN_PRO_SUCCESS,
                            payload: res.data,
                        })
                    );
                    if (res.data.pro.active === 0) {
                        history(`/todoo/config-info-pro`);
                    } else {
                        history(`/todoo/reservations-list`);
                    }
                }
            })
            .catch((error) => {
                dispatch(setAlert(errorMessage, "error"));
            });
    } catch (error) {
        console.log(error.response.data.error);
    }
};

// export const activateUserAction = (datapro) => async dispatch => {
//     try{
//         const headers = {
//             headers: { 'Authorization': `Bearer ${datapro.access_token}` }
//         }
//         const data = {
//             id: datapro.pro.id
//         }
//         await axios.post(`${config.API_SERVER}/api/pro/active`, data, headers)
//         .then(res => {

//             if(res.status === 200){
//                 dispatch({
//                     type: LOGIN_PRO_SUCCESS,
//                     payload: datapro
//                 });

//                 localStorage.setItem('userLoggedIn', JSON.stringify({
//                     type: LOGIN_PRO_SUCCESS,
//                     payload: datapro
//                 }))
//             }

//         }).catch(error => {
//             console.log(error);
//         })
//     }catch(error) {
//         console.log(error);
//     }
// }

export const activateUserAction = (token, user) => async (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const data = {
            id: user.id,
        };
        await axios
            .post(`${config.API_SERVER}/api/pro/active`, data, headers)
            .then((res) => {
                if (res.status === 200) {
                    let datalog = JSON.parse(
                        localStorage.getItem("userLoggedIn")
                    );
                    datalog.payload.pro.active = 1;
                    localStorage.setItem(
                        "userLoggedIn",
                        JSON.stringify({
                            type: LOGIN_PRO_SUCCESS,
                            payload: datalog.payload,
                        })
                    );

                    user.active = 1;
                    dispatch(updatePro(user));
                    // dispatch({
                    //     type: LOGIN_PRO_SUCCESS,
                    //     payload: datapro
                    // });

                    // localStorage.setItem('userLoggedIn', JSON.stringify({
                    //     type: LOGIN_PRO_SUCCESS,
                    //     payload: datapro
                    // }))
                }
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (error) {
        console.log(error);
    }
};

// const handleLoginClient = async (formData) => {

//     try {
//         const dataApi = {
//             email: formData.email,
//             password: formData.password,
//         };

//         if (formData.remember) {
//             data.remember = true;
//         }
//         let getData = null

//         const { data, status } = await axios.post(`${config.API_SERVER}/api/login/client`, dataApi)

//         return false
//     } catch (error) {
//         console.log('Aqui estamos')
//     }

// }

export const authClientAction = (formData, errorMessage, history) => async (dispatch) => {
    try {
        const dataApi = {
            email: formData.email,
            password: formData.password,
        };

        if (formData.remember) {
            dataApi.remember = true;
        }

        let getData = null

        const { data, status } = await axios.post(`${config.API_SERVER}/api/login/client`, dataApi)
        if (status === 200) {

            dispatch({
                type: LOGIN_CLIENT_SUCCESS,
                payload: data,
            });

            localStorage.setItem(
                "userLoggedIn",
                JSON.stringify({
                    type: LOGIN_CLIENT_SUCCESS,
                    payload: data,
                })
            );
            getData = {
                access_token: data.access_token,
                clientId: data.client.id
            }

            const getHistory = await fetch(
                `${config.API_SERVER}/api/client/history/comment/all`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${getData.access_token}` }
            }
            );

            const historyClient = await getHistory.json();

            if (historyClient.length > 0) {
                history(`/client-comment-pro/${getData.clientId}/${historyClient[0].prId}/${historyClient[0].week_date}`);
            } else {
                history(`/client/request-list`);
            }
        }

    } catch (error) {
        if (error.response.status === 404) {
            dispatch(authProAction(formData, errorMessage, history))
        }
    }
};


export const authClientActionCopy =
    (formData, errorMessage, history) => async (dispatch) => {
        try {
            const data = {
                email: formData.email,
                password: formData.password,
            };

            if (formData.remember) {
                data.remember = true;
            }
            let getData = null
            await axios
                .post(`${config.API_SERVER}/api/login/client`, data)
                .then((res) => {
                    if (res.status === 200) {

                        dispatch({
                            type: LOGIN_CLIENT_SUCCESS,
                            payload: res.data,
                        });

                        localStorage.setItem(
                            "userLoggedIn",
                            JSON.stringify({
                                type: LOGIN_CLIENT_SUCCESS,
                                payload: res.data,
                            })
                        );
                        getData = {
                            access_token: res.data.access_token,
                            clientId: res.data.client.id
                        }
                        //return getData;
                    }
                })
                .catch((error) => {
                    dispatch(setAlert(errorMessage, "error"));
                });

            const getHistory = await fetch(
                `${config.API_SERVER}/api/client/history/comment/all`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${getData.access_token}` }
            }
            );

            const historyClient = await getHistory.json();

            if (historyClient.length > 0) {
                history(`/client-comment-pro/${getData.clientId}/${historyClient[0].prId}/${historyClient[0].week_date}`);
            } else {
                history(`/client/request-list`);
            }

        } catch (error) {
            console.log(error);
        }
    };

export const getUserLoggedInAction = () => (dispatch) => {
    if (localStorage.getItem("userLoggedIn")) {
        const userLoggedIn = JSON.parse(localStorage.getItem("userLoggedIn"));
        dispatch({
            type: userLoggedIn.type,
            payload: userLoggedIn.payload,
        });
    }
};

export const logOutActionNoToken = () => async (dispatch) => {
    try {
        dispatch({
            type: "LOG_OUT",
        });
    } catch (error) {
        console.log(error);
    }
};

export const logOutAction = (token, history, redirectedUrl) => async (dispatch) => {
    try {
        dispatch({
            type: "LOG_OUT",
        });
        
        history(redirectedUrl);
        // const headers = {
        //     headers: { 'Authorization': `Bearer ${token}` }
        // }
        
        // await axios.post(`${config.API_SERVER}/api/logout/pro`, headers)
        // .then(res => {
        //     if (res.data.status === 200) {
        //         if (localStorage.getItem('userLoggedIn')) {
        //             dispatch({
        //                 type: 'LOG_OUT',
        //             });
        //             history(redirectedUrl);
        //         }
        //     }
        // }).catch((error) => {
        //     console.log('Error: ', error)
        // });

    } catch (error) {
        console.log(error);
    }
};

export const updateScheduleAuth = (data) => async (dispatch) => {
    dispatch({
        type: UPDATE_SCHEDULE_PRO,
        payload: data,
    });

    let datalog = JSON.parse(localStorage.getItem("userLoggedIn"));

    datalog.payload.pro.availability = data;
    localStorage.setItem(
        "userLoggedIn",
        JSON.stringify({
            type: LOGIN_PRO_SUCCESS,
            payload: datalog.payload,
        })
    );
};

export const updateUnavailabilityPro = (data) => async (dispatch) => {
    dispatch({
        type: UPDATE_AVAILABILITY_PRO,
        payload: data,
    });

    let datalog = JSON.parse(localStorage.getItem("userLoggedIn"));

    datalog.payload.pro.unavailability.push(data);
    localStorage.setItem(
        "userLoggedIn",
        JSON.stringify({
            type: LOGIN_PRO_SUCCESS,
            payload: datalog.payload,
        })
    );
};

export const updateDescriptionAuth = (token, description) => async (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const data = {
            description: description,
        };

        await axios
            .post(`${config.API_SERVER}/api/pro/description`, data, headers)
            .then((res) => {
                console.log('Respuesta: ', res)
                if (res.status === 200) {
                    let datalog = JSON.parse(
                        localStorage.getItem("userLoggedIn")
                    );
                    datalog.payload.pro.self_description = description;
                    localStorage.setItem(
                        "userLoggedIn",
                        JSON.stringify({
                            type: LOGIN_PRO_SUCCESS,
                            payload: datalog.payload,
                        })
                    );
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    } catch (error) {
        console.log(error.response.data);
    }
};

export const resetPassword = (token, email, formData, userType, history) => async (dispatch) => {
    try {
        const data = {
            token: token,
            email: email,
            password: formData.password,
            password_confirmation: formData.passwordConfirmation,
        };

        await axios
            .post(`${config.API_SERVER}/api/password/${userType}/reset`, data)
            .then((res) => {
                if (res.status === 200) {
                    const fData = {
                        email: email,
                        password: formData.password,
                    };
                    if(userType === 'pro'){
                        dispatch(authProAction(fData, "", history));
                    }else{
                        dispatch(authClientAction(fData, "", history));
                    }
                }
            })
            .catch((error) => {
                console.log(error.response.data);
                dispatch(setAlert("This link has expired", "error"));
            });

    } catch (error) {
        console.log(error);
    }
};

export const recoverPasswordClientAction = (email, lang, msg, noEmail) => async (dispatch) => {
    try {
        const data = {
            email: email,
            lag: lang === "fr" ? true : false,
        };

        await axios
            .post(`${config.API_SERVER}/api/password/client/email`, data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setAlert(msg, "success"));
                }
            })
    } catch (error) {
        dispatch(recoverPasswordAction(email, lang, msg, noEmail))
    }
};


export const recoverPasswordAction = (email, lang, msg, noEmail) => async (dispatch) => {
    try {
        const data = {
            email: email,
            lag: lang === "fr" ? true : false,
        };

        await axios
            .post(`${config.API_SERVER}/api/password/pro/email`, data)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setAlert(msg, "success"));
                }
            })
    } catch (error) {
        dispatch(setAlert(noEmail, "error"));
    }
};

export const updatePasswordPro = (token, form) => async (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const data = {
            oldPassword: form.passwordOld,
            newPassword: form.password,
        };

        await axios
            .post(`${config.API_SERVER}/api/pro/updatePassword`, data, headers)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(
                        setAlert("The password has been changed", "success")
                    );
                    dispatch(cleanError());
                }
            })
            .catch((error) => {
                console.log("Error: ", error.response.data.errors.current[0]);
                dispatch(
                    updatePasswordProError(
                        error.response.data.errors.current[0]
                    )
                );
            });
    } catch (error) {
        console.log(error, "error");
    }
};

export const updatePasswordClient = (token, form) => async (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const data = {
            oldPassword: form.passwordOld,
            newPassword: form.password,
        };

        await axios
            .post(
                `${config.API_SERVER}/api/client/updatePassword`,
                data,
                headers
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(
                        setAlert("The password has been changed", "success")
                    );
                    dispatch(cleanError());
                }
            })
            .catch((error) => {
                dispatch(
                    updatePasswordProError(
                        error.response.data.errors.current[0]
                    )
                );
            });
    } catch (error) {
        console.log(error);
    }
};

export const updatePasswordProError = (error) => async (dispatch) => {
    dispatch({
        type: UPDATE_GENERIC_ERROR,
        payload: { error: error },
    });
};
